import ENUMS_DATA from '../../.hireforce/data/enums.json'
import ENUMS_DATA_JA from '../../.hireforce/data/enums_ja.json'

type IEnumType =
  | 'All'
  | ''
  | 'ProfileSourced'
  | 'JobRemoteStatus'
  | 'JobCurrency'
  | 'JobEducation'
  | 'JobEmployment'
  | 'JobJobLevel'
  | 'JobStatus'
  | 'JobTypeOfSalary'
  | 'CompanySize'
  | 'Company'
  | 'InterviewFeedbackEvaluation'
  | 'InterviewEvent'
  | 'EmailKind'
  | 'StageGroup'
  | 'CompanyKind'
  | 'CompanyLanguage'
  | 'Provider'
  | 'FromProduct'
  | 'UserLanguage'
  | 'Status'
  | 'ApplicantStatus'
  | 'ApplicantIncoming'
  | 'InterviewState'
  | 'IkitOverallFeedback'
  | 'JobIkitStatus'
  | 'TalentPoolStatus'
  | 'TaskStatus'
  | 'AgencyCompanySize'
  | 'RejectedReasonKind'
  | 'USettingKind'
  | 'USettingGroup'
  | 'RequisitionStatus'
  | 'RequisitionReason'
  | 'RequisitionCurrency'
  | 'RequisitionTypeOfSalary'
  | 'RequisitionStepStatus'
  | 'JobRecruiterResponsibility'
  | 'PlacementStatus'
  | 'PlacementTypeOfSalary'
  | 'PlacementCurrencyOfRevenue'
  | 'PlacementTypeOfFee'
  | 'HfAgencyJobRecruiterResponsibility'
  | 'CustomFieldObjectKind'
  | 'CustomFieldKind'
  | 'ProfileProfileLevel'
  | 'CompanyCurrency'

const useEnumsData = ({
  enumType = 'All',
  locale = 'en'
}: {
  enumType: IEnumType
  locale?: string
}) => {
  const filter = (locale === 'en' ? ENUMS_DATA : ENUMS_DATA_JA || []).filter(
    (item) => item?.data?.__type?.name === enumType
  )?.[0]

  return (filter?.data?.__type?.enumValues || []).map((item) => ({
    value: String(item.name),
    supportingObj: {
      name: item.description || ''
    }
  })) as any
}

export default useEnumsData
